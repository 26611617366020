import { Alerts, CandidateData } from "./ResumeFormattingModel";

export function EARAlert(data: CandidateData): Alerts {
    const alerts: Alerts = {
        'Missing Values': {
            'Personal Details': [],
            'AcademicBackground': [],
            'Career Path': [],
            'Compensation Information': [],
            'Languages Known': []
        },
        'Gaps': []
    };

    // Helper function to add alerts for missing fields
    function addAlert(category: keyof Alerts['Missing Values'], message: string) {
        if (!alerts['Missing Values'][category].includes(message)) {
            alerts['Missing Values'][category].push(message);
        }
    }

    // Helper function to check for missing values in objects
    function checkEmptyFields(obj: any, path: string, category: keyof Alerts['Missing Values'], organization?: string) {
        const missingFields = new Set<string>();

        for (const key in obj) {
            const currentPath = path ? `${path}.${key}` : key;

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                if (Array.isArray(obj[key])) {
                    checkArrayItems(obj[key], currentPath, category, organization);
                } else {
                    if (key !== 'Qualification') {
                        checkEmptyFields(obj[key], currentPath, category, organization);
                    }
                }
            } else {
                if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
                    missingFields.add(key);
                }
            }
        }

        if (missingFields.size > 0) {
            const fieldNames = Array.from(missingFields).map(f => formatFieldName(f)).join(', ');
            addAlert(category, `${fieldNames} in ${path}` + (organization ? ` at ${organization}` : ''));
        }
    }

    // Helper function to format field names to a user-friendly format
    function formatFieldName(field: string): string {
        return field.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    // Helper function to check for missing values in arrays
    function checkArrayItems(array: any[], path: string, category: keyof Alerts['Missing Values'], organization?: string) {
        array.forEach((item, index) => {
            if (Array.isArray(item)) {
                checkArrayItems(item, `${path}[${index}]`, category, organization);
            } else if (typeof item === 'object') {
                checkEmptyFields(item, `${path}[${index}]`, category, organization);
            } else {
                if (item === '') {
                    addAlert(category, `Empty entry at ${path}[${index}]`);
                }
            }
        });
    }

    // Check PersonalInformation
    checkEmptyFields(data.PersonalInformation, 'Personal Details', 'Personal Details');

    // Check AcademicBackground array
    data.AcademicBackground.forEach((item) => {
        checkEmptyFields(item, item.Qualification, 'AcademicBackground');
    });

    // Check CareerSynopsis array
    data.CareerSynopsis.forEach((item) => {
        checkEmptyFields(item, `Career Path`, 'Career Path', item.Organization);

        // Specific check for missing Organization
        if (!item.Organization) {
            addAlert('Career Path', `Missing Organization in Career Path`);
        }
    });

    // Check CompensationInformation
    checkEmptyFields(data.compensationInformation, 'Compensation Information', 'Compensation Information');

    // Check LanguagesKnown
    if (Array.isArray(data.LanguagesKnown)) {
        if (data.LanguagesKnown.length === 0) {
            alerts['Missing Values']['Languages Known'].push(`No language details provided`);
        }
        if (data.LanguagesKnown.length > 0 && data.LanguagesKnown[0] == "") {
            alerts['Missing Values']['Languages Known'].push(`Empty language entry in Languages Known`);
        }
    }

    // Function to parse date strings into Date objects
    function parseDateString(dateStr: string) {
        // Assume the format is "MMM-YYYY", e.g., "Jan-2023"
        return new Date(dateStr + '-01'); // Use the 1st of the month for comparison
    }

    // Sort the CareerSynopsis array in ascending order based on StartTime
    const sortedCareerSynopsis = data.CareerSynopsis.sort((a, b) => {
        const startDateA = parseDateString(a.StartTime);
        const startDateB = parseDateString(b.StartTime);
        return startDateA.getTime() - startDateB.getTime(); // Sort in ascending order
    });

    // Use the sorted data to check for gaps
    for (let i = 0; i < sortedCareerSynopsis.length - 1; i++) {
        const currentEnd = parseDateString(sortedCareerSynopsis[i].EndTime);
        const nextStart = parseDateString(sortedCareerSynopsis[i + 1].StartTime);

        // Calculate the gap in months
        const gapMonths = (nextStart.getFullYear() - currentEnd.getFullYear()) * 12 + (nextStart.getMonth() - currentEnd.getMonth());

        // If the gap is greater than 1 month, log it in the alerts
        if (gapMonths > 1) {
            alerts.Gaps.push(`There is a gap of ${gapMonths} months between ${sortedCareerSynopsis[i].EndTime} and ${sortedCareerSynopsis[i + 1].StartTime} in Career Path`);
        }
    }


    return alerts;
}

export function FCVAlert(data: CandidateData): Alerts {
    const alerts: Alerts = {
        'Missing Values': {
            'Personal Details': [],
            'AcademicBackground': [],
            'Career Path': [],
            'Compensation Information': [],
            'Languages Known': []
        },
        'Gaps': []
    };

    // Helper function to add alerts for missing fields
    function addAlert(category: keyof Alerts['Missing Values'], message: string) {
        if (!alerts['Missing Values'][category].includes(message)) {
            alerts['Missing Values'][category].push(message);
        }
    }

    // Helper function to check for missing values in objects
    function checkEmptyFields(obj: any, path: string, category: keyof Alerts['Missing Values'], organization?: string) {
        const missingFields = new Set<string>();

        for (const key in obj) {
            const currentPath = path ? `${path}.${key}` : key;

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                if (Array.isArray(obj[key])) {
                    checkArrayItems(obj[key], currentPath, category, organization);
                } else {
                    checkEmptyFields(obj[key], currentPath, category, organization);
                }
            } else {
                if (!obj[key]) {
                    missingFields.add(formatFieldName(key));
                }
            }
        }

        if (missingFields.size > 0) {
            const fieldNames = Array.from(missingFields).join(', ');
            addAlert(category, `${fieldNames} missing in ${path}` + (organization ? ` at ${organization}` : ''));
        }
    }

    // Helper function to format field names to a user-friendly format
    function formatFieldName(field: string): string {
        return field.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, c => c.toUpperCase());
    }

    // Helper function to check for missing values in arrays
    function checkArrayItems(array: any[], path: string, category: keyof Alerts['Missing Values'], organization?: string) {
        array.forEach((item, index) => {
            const currentPath = `${path}[${index}]`;
            if (Array.isArray(item)) {
                checkArrayItems(item, currentPath, category, organization);
            } else if (typeof item === 'object') {
                checkEmptyFields(item, currentPath, category, organization);
            } else if (!item) {
                addAlert(category, `Empty entry at ${currentPath}`);
            }
        });
    }

    // Specific check for DateOfBirth in PersonalInformation
    const dob = data.PersonalInformation?.DateOfBirth;
    console.log(dob)
    if (dob) {
        const { Date, Month, Year, Age } = dob;
        console.log(Date, Month, Year, Age)

        if (!Age) { // Only check if Age is not provided
            if (!Date && !Month && !Year) {
                addAlert('Personal Details', 'DOB ');
            } else {
                if (!Date) addAlert('Personal Details', 'Date in DOB ');
                if (!Month) addAlert('Personal Details', 'Month in DOB ');
                if (!Year) addAlert('Personal Details', 'Year in DOB ');
            }
        }
    } else {
        addAlert('Personal Details', 'DOB ');
    }

    // Specific check for FamilyDetails in PersonalInformation
    const familyDetails = data.PersonalInformation?.FamilyDetails;
    if (familyDetails) {
        const { Immediate, Extended, MaritalStatus } = familyDetails;

        if (!Immediate && !Extended && !MaritalStatus) { // Only check if Age is not provided
            addAlert('Personal Details', 'Family Details');
        }
    } else {
        addAlert('Personal Details', 'Family Details ');
    }

    // Specific check for CurrentLocation in PersonalInformation 
    const currentLocation = data.PersonalInformation?.CurrentLocation;
    if (!currentLocation) {
        addAlert('Personal Details', 'Current Location ');
    }

    // Specific check for NoticePeriod in PersonalInformation 
    const noticePeriod = data.compensationInformation?.NoticePeriod;
    if (!noticePeriod) {
        addAlert('Personal Details', 'Notice Period ');
    }

    // Specific check for Availability in PersonalInformation 
    const availability = data.compensationInformation?.Availability;
    if (!availability) {
        addAlert('Personal Details', 'Availability ');
    }

    // Specific check for Availability in PersonalInformation 
    const compensationPerAnnum = data.compensationInformation?.CurrentCompensation?.Total;
    if (!compensationPerAnnum) {
        addAlert('Personal Details', 'Compensation Per Annum ');
    }

    // Specific check for Expected Salary in PersonalInformation 
    const expectedTotalCompensation = data.compensationInformation?.ExpectedTotalCompensation;
    if (!expectedTotalCompensation) {
        addAlert('Personal Details', 'Expected Salary ');
    }

    // Check AcademicBackground array
    data.AcademicBackground.forEach((item, index) => {
        const path = `Academic Background[${index}]`;
        const missingFields = [];

        if (!item.StartYear) missingFields.push('StartYear');
        if (!item.EndYear) missingFields.push('EndYear');
        if (!item.InstitutionAndUniversityAndCity) missingFields.push('Institution');

        if (missingFields.length > 0) {
            const fieldNames = missingFields.join(', ');
            addAlert('AcademicBackground', `${fieldNames} missing in ${item.Qualification} at ${path}`);
        }
    });

    // Check CareerSynopsis array
    data.CareerSynopsis.forEach((item, index) => {
        const path = `Career Path[${index}]`;
        const missingFields = [];

        if (!item.Organization) missingFields.push('Organization');
        if (!item.StartTime) missingFields.push('Start Time');
        if (!item.EndTime) missingFields.push('End Time');
        if (Object.keys(item.Designation).length === 0) missingFields.push('Designation');

        if (missingFields.length > 0) {
            const fieldNames = missingFields.join(', ');
            addAlert('Career Path', `${fieldNames} missing in ${path}`);
        }
    });

    // Function to parse date strings into Date objects
    function parseDateString(dateStr: string) {
        return new Date(`${dateStr}-01`); // Use the 1st of the month for comparison
    }

    // Sort the CareerSynopsis array in ascending order based on StartTime
    const sortedCareerSynopsis = data.CareerSynopsis.sort((a, b) => {
        const startDateA = parseDateString(a.StartTime);
        const startDateB = parseDateString(b.StartTime);
        return startDateA.getTime() - startDateB.getTime(); // Sort in ascending order
    });

    // Use the sorted data to check for gaps
    for (let i = 0; i < sortedCareerSynopsis.length - 1; i++) {
        const currentEnd = parseDateString(sortedCareerSynopsis[i].EndTime);
        const nextStart = parseDateString(sortedCareerSynopsis[i + 1].StartTime);

        // Calculate the gap in months
        const gapMonths = (nextStart.getFullYear() - currentEnd.getFullYear()) * 12 + (nextStart.getMonth() - currentEnd.getMonth());

        // If the gap is greater than 1 month, log it in the alerts
        if (gapMonths > 1) {
            alerts.Gaps.push(`There is a gap of ${gapMonths} months between ${sortedCareerSynopsis[i].EndTime} and ${sortedCareerSynopsis[i + 1].StartTime} in Career Path`);
        }
    }

    console.log(alerts)
    return alerts;
}
