import React, { useEffect, useState } from 'react'
import { Blocks } from 'react-loader-spinner'
import { KTIcon } from '../../../_metronic/helpers'
import { updateDownloadCount } from '../../api'
import { useAuth } from '../auth'
import { EARAlert, FCVAlert } from './AlertFunctions'
import { Alerts, ResumeTableProps } from './ResumeFormattingModel'

export const ResumeTable: React.FC<ResumeTableProps> = ({
    className, resumeData, setLink, type, fetching, formatting, handleResumeProcess, compare, setSelectedFiles
}) => {
    const [fileLink, setFileLink] = useState<string>()
    const [processingFile, setProcessingFile] = useState<string>('')
    const [processing, setProcessing] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<Alerts>({
        'Missing Values': {
            'Personal Details': [],
            'AcademicBackground': [],
            'Career Path': [],
            'Compensation Information': [],
            'Languages Known': []
        },
        'Gaps': [],
    });
    const { currentUser } = useAuth()

    const handleDownloadCount = (process_file_url: string) => {
        updateDownloadCount({ useremail: currentUser?.email, process_file_url }).then(() => { })
    }

    useEffect(() => {
        setSelectedFiles([])
    }, [resumeData])

    return (
        <div>
            {/* EAR Alert Modal */}
            <div className="modal fade" id="kt_modal_show_ear_alert" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-850px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h2 className="fw-bolder">Resume Data Alert</h2>
                                <small>Please review the following details that need attention.</small>
                            </div>
                            <button className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" type='button'>
                                <KTIcon iconName="cross" className="fs-1" />
                            </button>
                        </div>
                        <div className="modal-body scroll-y mx-5 mx-xl-15">
                            {Object.values(alertMessage['Missing Values']).some(section => section.length > 0) && (
                                <div className="alert-section">
                                    <h4>Missing Values</h4>
                                    {Object.entries(alertMessage['Missing Values']).map(([section, messages]) => (
                                        messages.length > 0 && (
                                            <ul key={section}>
                                                <li><h5>{section}</h5></li>
                                                <ul>
                                                    {messages.map((msg, index) => (
                                                        <li key={index}>
                                                            <span className='text-danger'>{msg.slice(0, msg.indexOf(" in "))}</span>
                                                            {section !== "Personal Details" && <span>{msg.slice(msg.indexOf("in"))}</span>}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </ul>
                                        )
                                    ))}
                                </div>
                            )}
                            {alertMessage['Gaps'].length > 0 && (
                                <div className="alert-section">
                                    <h4>Gaps</h4>
                                    <ul>
                                        {alertMessage['Gaps'].map((msg, index) => (
                                            <li key={index}>
                                                {msg.startsWith('There is a gap of ') && (
                                                    <>
                                                        There is a gap of <span className="text-danger">{msg.substring(17, msg.indexOf(' between'))}</span>
                                                        between <span className="text-danger">{msg.substring(msg.indexOf(' between ') + 9, msg.indexOf(' and '))}</span>
                                                        and <span className="text-danger">{msg.substring(msg.indexOf(' and ') + 5, msg.lastIndexOf(' in '))}</span>
                                                        in <span className="text-danger">{msg.substring(msg.lastIndexOf(' in ') + 3)}</span>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {Object.values(alertMessage['Missing Values']).flat().length === 0 && alertMessage['Gaps'].length === 0 && (
                                <p className="fs-1 fw-bold text-center">No issues detected</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* FCV Alert Modal */}
            <div className="modal fade" id="kt_modal_show_fcv_alert" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-850px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="fw-bolder">Resume Data Alert</h2>
                            <small>Please review the following details that need attention.</small>
                            <button className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" type='button'>
                                <KTIcon iconName="cross" className="fs-1" />
                            </button>
                        </div>
                        <div className="modal-body scroll-y mx-5 mx-xl-15">
                            {Object.values(alertMessage['Missing Values']).some(section => section.length > 0) && (
                                <div className="alert-section">
                                    <h4>Missing Values</h4>
                                    {Object.entries(alertMessage['Missing Values']).map(([section, messages]) => (
                                        messages.length > 0 && (
                                            <ul key={section}>
                                                <li><h5>{section}</h5></li>
                                                <ul>
                                                    {messages.map((msg, index) => (
                                                        <li key={index}>
                                                            <span className='text-danger'>{msg.slice(0, msg.indexOf(" in "))}</span>
                                                            {section !== "Personal Details" && <span>{msg.slice(msg.indexOf("in"))}</span>}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </ul>
                                        )
                                    ))}
                                </div>
                            )}
                            {alertMessage['Gaps'].length > 0 && (
                                <div className="alert-section">
                                    <h4>Gaps</h4>
                                    <ul>
                                        {alertMessage['Gaps'].map((msg, index) => (
                                            <li key={index}>
                                                {msg.startsWith('There is a gap of ') && (
                                                    <>
                                                        There is a gap of <span className="text-danger">{msg.substring(17, msg.indexOf(' between'))}</span>
                                                        between <span className="text-danger">{msg.substring(msg.indexOf(' between ') + 9, msg.indexOf(' and '))}</span>
                                                        and <span className="text-danger">{msg.substring(msg.indexOf(' and ') + 5, msg.lastIndexOf(' in '))}</span>
                                                        in <span className="text-danger">{msg.substring(msg.lastIndexOf(' in ') + 3)}</span>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {Object.values(alertMessage['Missing Values']).flat().length === 0 && alertMessage['Gaps'].length === 0 && (
                                <p className="fs-1 fw-bold text-center">No issues detected</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={`card ${className}`}>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        {fetching ? (
                            <div className="row align-items-center">
                                {resumeData.length === 0 ? (
                                    <Blocks visible={true} height="80" width="80" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
                                ) : (
                                    <div className="card-body" />
                                )}
                            </div>
                        ) : (
                            <>
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    <thead>
                                        <tr className='fw-bold text-muted bg-light p-2'>
                                            <th className='ps-4 min-w-100px rounded-start'>Original File</th>
                                            {(type === 'processed' || type === 'EAR') && <th className='min-w-120px'>Processed File</th>}
                                            {(type === 'processed' || type === 'EAR') && <th className='min-w-120px'>Process Time</th>}
                                            <th className='min-w-100px'>Status</th>
                                            {/* <th className='min-w-100px'>Assign To</th> */}
                                            <th className='min-w-120px text-end px-5'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resumeData.length > 0 ? resumeData.map((resume, index) => (
                                            <tr key={index}>
                                                <td className='d-flex'>
                                                    {processing && fileLink === resume.uploaded_file_url && (
                                                        <span className="indicator-progress me-5" style={{ display: "block" }}>
                                                            <span className="spinner-border align-middle ms-2 fs-3x"></span>
                                                        </span>
                                                    )}
                                                    <div>
                                                        <p
                                                            style={{ cursor: 'pointer' }}
                                                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                                            title={resume.original_file_name}
                                                        >
                                                            {resume.original_file_name.length > 20
                                                                ? `${resume.original_file_name.slice(0, 20)}...`
                                                                : resume.original_file_name}
                                                        </p>
                                                        <span className='text-dark fw-bold d-block fs-8'>
                                                            {new Date(resume.upload_date).toLocaleString()}
                                                        </span>
                                                    </div>
                                                </td>
                                                {(type === 'processed' || type === 'EAR') && (
                                                    <>
                                                        <td>
                                                            <p
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => setLink(resume.process_file_url)}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#kt_modal_preview"
                                                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                                                title={resume.processed_file_name}
                                                            >
                                                                {resume.processed_file_name.length > 20
                                                                    ? `${resume.processed_file_name.slice(0, 20)}...`
                                                                    : resume.processed_file_name}
                                                            </p>
                                                            <span className='text-dark fw-bold d-block fs-8'>
                                                                {new Date(resume.process_date).toLocaleString()}
                                                            </span>
                                                        </td>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {resume.process_time.slice(resume.process_time.indexOf(':') + 1, resume.process_time.indexOf(':') + 6)} min
                                                        </td>
                                                    </>
                                                )}
                                                <td>
                                                    <span className={`badge badge-light-${resume.processed ? 'success' : 'danger'}`}>
                                                        {resume.processed ? 'Processed' : 'Unprocessed'}
                                                    </span>
                                                </td>
                                                {/* <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {resume.assign_to || 'Not Assigned'}
                                                    </a>
                                                </td> */}
                                                <td className='text-end px-4'>
                                                    {
                                                        type == 'EAR' &&
                                                        <>
                                                            <a
                                                                href='#'
                                                                onClick={(e) => {
                                                                    setSelectedFiles([])
                                                                    compare(resume.original_file_name, resume.uploaded_file_url, resume.process_file_url_ear, resume.output, 'EAR')
                                                                }}
                                                                className='btn btn-primary btn-sm w-120px fs-8 mx-1'
                                                            >
                                                                <KTIcon iconName='switch' className='fs-3' /> {" "} Validate
                                                            </a>
                                                            <a
                                                                href={resume.process_file_url_ear}
                                                                onClick={(e) => handleDownloadCount(resume.process_file_url_ear)}
                                                                className='btn btn-success btn-sm w-120px fs-8 mx-1'
                                                            >
                                                                <KTIcon iconName='file-down' className='fs-3' /> {" "} Download
                                                            </a>
                                                            {/* <button
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#kt_modal_show_ear_alert"
                                                                onClick={(e) => {
                                                                    setAlertMessage(EARAlert(resume.output))
                                                                }}
                                                                type='button'
                                                                className="btn btn-icon btn-danger btn-sm w-120px fs-8 mx-1"
                                                            >
                                                                <KTIcon iconName='notification' className='fs-4 fw-bolder' />
                                                            </button> */}
                                                        </>
                                                    }
                                                    {
                                                        type == 'processed' &&
                                                        <>
                                                            <button
                                                                disabled={resume.process_file_url_ear != null}
                                                                type="button"
                                                                onClick={(e) => {
                                                                    setLink(resume.uploaded_file_url);
                                                                    setProcessingFile(resume.uploaded_file_url)
                                                                    handleResumeProcess(resume.uploaded_file_url, 'EAR');
                                                                }}
                                                                className={`btn ${resume.process_file_url_ear === null ? 'btn-info' : 'btn-light'} btn-sm w-150px fs-8 mx-1`}
                                                            >
                                                                {!formatting && <>
                                                                    {" "}
                                                                    {resume.process_file_url_ear === null ?
                                                                        <>
                                                                            {/* <KTIcon iconName='pencil' className='fs-3' /> */}
                                                                            Convert To EAR
                                                                        </>
                                                                        : <> EAR Generated</>
                                                                    }
                                                                </>}
                                                                {formatting && (resume.uploaded_file_url == processingFile ? (
                                                                    <span className="indicator-progress" style={{ display: "block" }}>
                                                                        Processesing...
                                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                    </span>) :
                                                                    <>
                                                                        <KTIcon iconName='pencil' className='fs-3' />
                                                                        {" "}
                                                                        Convert to EAR
                                                                    </>
                                                                )}
                                                            </button>
                                                            <a
                                                                href='#'
                                                                onClick={(e) => {
                                                                    setSelectedFiles([])
                                                                    compare(resume.original_file_name, resume.uploaded_file_url, resume.process_file_url_fvc, resume.output, 'FCV')
                                                                }}
                                                                className='btn btn-primary btn-sm w-120px fs-8 mx-1'
                                                            >
                                                                <KTIcon iconName='switch' className='fs-3' /> {" "} Validate
                                                            </a>
                                                            <a
                                                                href={resume.process_file_url_fvc}
                                                                onClick={(e) => handleDownloadCount(resume.process_file_url_fvc)}
                                                                className='btn btn-success btn-sm w-120px fs-8 mx-1'
                                                            >
                                                                <KTIcon iconName='file-down' className='fs-3' /> {" "} Download
                                                            </a>
                                                            {/* <button
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#kt_modal_show_fcv_alert"
                                                                onClick={(e) => {
                                                                    setAlertMessage(EARAlert(resume.output))
                                                                }}
                                                                type='button'
                                                                className="btn btn-icon btn-danger btn-sm w-120px fs-8 mx-1"
                                                            >
                                                                <KTIcon iconName='notification' className='fs-4 fw-bolder' />
                                                            </button> */}
                                                        </>
                                                    }
                                                    {
                                                        type == 'unprocessed' &&
                                                        <a
                                                            type="button"
                                                            onClick={(e) => {
                                                                setLink(resume.uploaded_file_url);
                                                                setProcessingFile(resume.uploaded_file_url)
                                                                handleResumeProcess(resume.uploaded_file_url, 'FCV');
                                                            }}
                                                            className='btn btn-primary my-3 me-3 w-120px'
                                                        >
                                                            {!formatting && <>
                                                                <KTIcon iconName='pencil' className='fs-3' />
                                                                {" "}
                                                                Process
                                                            </>}
                                                            {formatting && (resume.uploaded_file_url == processingFile ? (
                                                                <span className="indicator-progress" style={{ display: "block" }}>
                                                                    Processesing...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>) :
                                                                <>
                                                                    <KTIcon iconName='pencil' className='fs-3' />
                                                                    {" "}
                                                                    Process
                                                                </>
                                                            )}
                                                        </a>
                                                    }
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td className='text-center p-3' colSpan={6}>
                                                    <span className='fw-semibold text-muted text-center fs-3'>No resumes available</span>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                </div>
            </div >
        </div >
    )
}
